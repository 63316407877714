import { Routes } from '@angular/router';
import { requireAuthGuard } from './guards/require-auth.guard';
import { RouteConstants } from './services/routing.constants';
import { loadChildren, loadComponent } from './services/helper-functions';
import { ProcessingHttpInterceptorStateService } from './services/processing-http-interceptor-state.service';
import { Entitlements } from './openapi';
import { requirePermissionGuard } from './guards/require-permission.guard';

export const routes: (interceptorState: ProcessingHttpInterceptorStateService) => Routes = (interceptorState: ProcessingHttpInterceptorStateService) => [
  {
    path: RouteConstants.Home,
    loadComponent: loadComponent(() => import('./homepage/homepage.component').then(i => i.HomepageComponent), interceptorState),
  },
  {
    path: RouteConstants.AdminPlans,
    canActivate: [requireAuthGuard, requirePermissionGuard([Entitlements.ManagePlans])],
    loadComponent: loadComponent(() => import('./admin/plans/plans.component').then(i => i.PlansComponent), interceptorState),
    loadChildren: loadChildren(() => import('./admin/plans/plans.routes').then(i => i.routes(interceptorState)), interceptorState),
  },
  {
    path: `${RouteConstants.Public}/:eventId`,
    canActivate: [requireAuthGuard],
    loadComponent: loadComponent(() => import('./public/public.component').then(i => i.PublicComponent), interceptorState),
    loadChildren: loadChildren(() => import('./public/public.routes').then(i => i.routes(interceptorState)), interceptorState),
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: RouteConstants.Manage,
    canActivate: [requireAuthGuard],
    loadComponent: loadComponent(() => import('./manage/manage.component').then(i => i.ManageComponent), interceptorState),
    loadChildren: loadChildren(() => import('./manage/manage.routes').then(i => i.routes(interceptorState)), interceptorState),
  },
  {
    path: RouteConstants.Pricing,
    canActivate: [requireAuthGuard],
    loadComponent: loadComponent(() => import('./pricing/pricing.component').then(i => i.PricingComponent), interceptorState),
  },
  {
    path: RouteConstants.Profile,
    canActivate: [requireAuthGuard],
    loadComponent: loadComponent(() => import('./profile/profile.component').then(i => i.ProfileComponent), interceptorState),
  },
  {
    path: '**',
    canActivate: [requireAuthGuard],
    loadComponent: loadComponent(() => import('./not-found/not-found.component').then(i => i.NotFoundComponent), interceptorState),
  },
];
