import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { switchMap } from 'rxjs';
import { Entitlements } from '../openapi';
import { EntitlementsService } from '../services/entitlements.service';
import { ModalService } from '../services/modal.service';
import { NoPermissionsModalComponent } from '../no-permissions-modal/no-permissions-modal.component';

export const requirePermissionGuard: (entitlements: Entitlements[]) => CanActivateFn = (entitlements: Entitlements[]) => () => {
  const entitlementsService = inject(EntitlementsService);
  const modalService = inject(ModalService);

  return entitlementsService.isEntitled(entitlements).pipe(
    switchMap(async isEntitled => {
      if (!isEntitled) {
        modalService.open(NoPermissionsModalComponent);
        return false;
      }
      return true;
    }),
  );
};
